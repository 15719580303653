<template>
  <div class="places-images">
    <img src="../assets/ktown.gif" />
  </div>
</template>

<script>
  export default {
    name: 'PlaceImages',
  }
</script>

<style lang="scss">
  .places-images {
    display: grid;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    max-width: 85vw;
    height: 400px;
  }

  @media (min-width: 800px) {
    .places-images {
      max-width: 100%;
      height: 475px;
    }
  }
</style>
