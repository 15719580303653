<template>
  <div class="home-page" ref="home-page-contents">
    <div class="images-container">
      <AuthorHeadshot v-if="displayHeadshot" />
      <PlaceImages v-if="displayPlaceImages" />
      <WriterImages v-if="displayWriterImages" />
    </div>

    <AuthorBio
      @clickOnName="onNameClick"
      @clickOnVocation="onVocationClick"
      @clickOnPlace="onPlaceClick"
    />

  </div>
</template>

<script>
import { inject, ref } from 'vue'
import AuthorBio from '@/components/AuthorBio.vue'
import AuthorHeadshot from '@/components/AuthorHeadshot.vue'
import PlaceImages from '@/components/PlaceImages.vue'
import WriterImages from '@/components/WriterImages.vue'

export default {
  name: 'HomePage',
  components: {
    AuthorBio,
    AuthorHeadshot,
    PlaceImages,
    WriterImages,
  },
  setup() {
    let displayHeadshot = ref(true);
    let displayPlaceImages = ref(false);
    let displayWriterImages = ref(false);

    const emitter = inject('emitter');
    emitter.on('displayHeadshot', (value) => {
      if (value == true) {
        revertToHeadshot();
      }
    });

    function revertToHeadshot() {
      displayHeadshot.value = true;
      displayPlaceImages.value = false;
      displayWriterImages.value = false;
    }

    function onNameClick() {
      revertToHeadshot();
    }

    function onPlaceClick() {
      displayPlaceImages.value = true;
      displayHeadshot.value = false;
      displayWriterImages.value = false;
    }

    function onVocationClick() {
      displayWriterImages.value = true;
      displayPlaceImages.value = false;
      displayHeadshot.value = false;
    }

    return {
      displayHeadshot,
      displayPlaceImages,
      displayWriterImages,
      revertToHeadshot,
      onNameClick,
      onPlaceClick,
      onVocationClick,
    }
  },
}
</script>

<style lang="scss">
  .home-page {
    display: grid;
    justify-content: center;
  }
</style>
