<template>
  <div class="writer-images">
    <img
      src="../assets/writing03.gif"
      :width="500"
      :height="250"
    />
  </div>
</template>

<script>
export default {
  name: 'WriterImages',
}
</script>

<style lang="scss">
  .writer-images {
    display: grid;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    max-width: 360px;
    height: 400px;
  }

  @media (min-width: 800px) {
    .writer-images {
      max-width: 100%;
      height: 475px;
    }
  }
</style>