<template>
  <div class="author-headshot">
    <img class="headshot" alt="headshot" src="../assets/janeskim.jpg"/>
  </div>
</template>

<script>
export default {
  name: 'AuthorHeadshot',
}
</script>

<style lang="scss">
  .author-headshot {
    display: grid;
    justify-content: center;

    img.headshot {
      cursor: text;
      max-width: 333px;
      height: 400px;
    }
  }

  @media (min-width: 800px) {
    .author-headshot {
      img.headshot {
        cursor: text;
        max-width: 333px;
        height: 475px;
      }
    }
  }
</style>
