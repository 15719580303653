<template>
  <footer>
    <div class="links-container">
      <a href="mailto:mail@janeskim.com">
        <img class="footer-icons" src="../assets/email.png"/>
      </a>
    </div>
  </footer>
</template>

<script>
  export default {
    name: 'PageFooter',
  }
</script>

<style lang="scss">
  @import url('https://fonts.googleapis.com/css2?family=family=Koulen&display=swap');

  footer {
    font-family: 'Koulen', cursive;
    display: flex;
    justify-content: center;
    margin-top: 100px;
    margin-bottom: 20px;

    .links-container {
      display: flex;
      justify-content: space-evenly;
      width: 500px;

      img.footer-icons {
        cursor: pointer;
        max-width: 30px;
        max-height: 30px;
      }
    }
  }
</style>
