<template>
  <div class="author-bio">
    <p class="about-title bio">
      <span
        class="name"
        :class="{ 'selected': nameSelected }"
        @click="onNameClick"
      >
        Jane S. Kim
      </span>
       is a 
      <span
        class="vocation"
        :class="{ 'wiggle': !vocationSelected, 'selected': vocationSelected }"
        @click="onVocationClick"
      >
        writer
      </span> 
      living in 
      <span
        class="place"
        :class="{ 'selected': placeSelected }"
        @click="onPlaceClick"
      >
        Los Angeles, CA
      </span>.
    </p>
    <p class="about-subtitle">
      She is a 2022 PEN America Emerging Voices Fellow<br>
      and she is currently at work on a short story collection.
    </p>
  </div>
</template>

<script>
import { inject, ref } from 'vue'

export default {
  name: 'AuthorBio',
  setup(props, context) {
    let onName = ref(false);
    let onVocation = ref(false);
    let onPlace = ref(false);
    let nameSelected = ref(false);
    let vocationSelected = ref(false);
    let placeSelected = ref(false);

    const emitter = inject('emitter');
    emitter.on('displayHeadshot', (value) => {
      if (value == true) {
        revertToUnselected();
      }
    });

    function revertToUnselected() {
      nameSelected.value = false;
      vocationSelected.value = false;
      placeSelected.value = false;
    }

    function onNameClick() {
      triggerNameSelectedStyles();

      onName.value = !onName.value;
      context.emit('clickOnName', onName);
    }

    function onVocationClick() {
      triggerVocationSelectedStyles();

      onVocation.value = !onVocation.value;
      context.emit('clickOnVocation', onVocation);
    }

    function onPlaceClick() {
      triggerPlaceSelectedStyles();

      onPlace.value = !onPlace.value;
      context.emit('clickOnPlace', onPlace);
    }

    function triggerNameSelectedStyles() {
      nameSelected.value = true;
    }

    function triggerVocationSelectedStyles() {
      disableVocationWiggle();

      vocationSelected.value = true;
    }

    function triggerPlaceSelectedStyles() {
      placeSelected.value = true;
    }

    function disableVocationWiggle() {
      vocationSelected.value = false;
    }

    return {
      onName,
      onVocation,
      onPlace,
      nameSelected,
      vocationSelected,
      placeSelected,
      revertToUnselected,
      onNameClick,
      onVocationClick,
      onPlaceClick,
      triggerNameSelectedStyles,
      triggerVocationSelectedStyles,
      triggerPlaceSelectedStyles,
      disableVocationWiggle,
    }
  },
}
</script>

<style lang="scss">
  @import url('https://fonts.googleapis.com/css2?family=Koulen&family=Creepster&family=Permanent+Marker&display=swap');
  @import url(//db.onlinewebfonts.com/c/33bc06ea126d3ff79886277122f1f510?family=Brush+Script+MT);

  @font-face {font-family: "Brush Script MT"; src: url("//db.onlinewebfonts.com/t/33bc06ea126d3ff79886277122f1f510.eot"); src: url("//db.onlinewebfonts.com/t/33bc06ea126d3ff79886277122f1f510.eot?#iefix") format("embedded-opentype"), url("//db.onlinewebfonts.com/t/33bc06ea126d3ff79886277122f1f510.woff2") format("woff2"), url("//db.onlinewebfonts.com/t/33bc06ea126d3ff79886277122f1f510.woff") format("woff"), url("//db.onlinewebfonts.com/t/33bc06ea126d3ff79886277122f1f510.ttf") format("truetype"), url("//db.onlinewebfonts.com/t/33bc06ea126d3ff79886277122f1f510.svg#Brush Script MT") format("svg"); }

  @keyframes wiggle {
    0% { transform: translate(1px, 1px) rotate(0deg); }
    40% { transform: translate(1px, -1px) rotate(1deg); }
    50% { transform: translate(-1px, 2px) rotate(-1deg); }
    70% { transform: translate(3px, 1px) rotate(-1deg); }
    80% { transform: translate(-1px, -1px) rotate(1deg); }
    90% { transform: translate(1px, 2px) rotate(0deg); }
    100% { transform: translate(1px, -2px) rotate(-1deg); }
  }
  .wiggle {
    animation: wiggle 2s infinite;
  }

  .author-bio {
    display: grid;
    justify-content: center;
    text-align: center;
    padding: 15px;
    max-width: 333px;
  }
  .about-title, .about-subtitle {
    font-size: 30px;
    font-family: 'Koulen', 'Arial';
    margin: 0;
  }

  .name {
    cursor: pointer;
    color: blue;
  }
  .name.selected {
    font-family: 'Creepster', cursive;
  }
  .vocation {
    cursor: pointer;
    color: #C4DF94;
    display: inline-block;
  }
  .vocation.selected {
    font-family: 'Permanent Marker', cursive;
    font-size: 25px;
  }
  .place {
    cursor: pointer;
    color: pink;
    display: inline-block;
  }
  .place.selected {
    cursor: pointer;
    font-family: "Brush Script MT";
  }

  @media (min-width: 800px) {
    .author-bio {
      max-width: 750px;
    }
  }
</style>
