<template>
  <nav class="page-header">
    <div class="nav-links-container-left">
      <router-link @click="displayHeadshot" to="/">
        <div class="home-button"/>
      </router-link>
    </div>
    <div class="nav-links-container-right">
      <router-link to="/writing">
        <span class="link-text">Writing</span>
      </router-link>
    </div>
  </nav>
</template>

<script>
  import { inject } from 'vue'

  export default {
    setup() {
      const emitter = inject('emitter');
      const displayHeadshot = () => {
        emitter.emit('displayHeadshot', true);
      };
      return {
        displayHeadshot
      }
    },
  }
</script>

<style lang="scss">
  @import url('https://fonts.googleapis.com/css2?family=Koulen&display=swap');

  nav {
    margin-bottom: 10px;
    display: flex;
    align-items: center;
    padding: .25rem;
    background-color: #DAF7A6;

    a {
      text-decoration: none;
      color: black;
      cursor: pointer;
    }

    .nav-links-container-right {
      display: flex;
      justify-content: end;
      width: 100%;
      margin-right: .5rem;
    }

    .nav-links-container-left {
      display: flex;
      justify-content: start;
      margin-left: .5rem;
    }

    .link-text {
      font-family: 'Koulen', cursive;
      font-size: 17px;
      display: inline-flex;
      margin-left: 1rem;
    }

    .home-button {
      width: 20px;
      height: 20px;
      border-radius: 100%;
      background-color: black;
      margin: auto;
    }
  }
</style>